import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialUserState: { data: any , State:any, involved_vehicle_count: number} = {
  data: null,
  State:[],
  involved_vehicle_count: 3,
};

const mv104Slice = createSlice({
  name: "mv104",
  initialState: initialUserState,
  reducers: {
    setDataRedux(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    setStateRedux(state, action: PayloadAction<any>) {
      state.State = action.payload;
    },
    setInvolvedVehicleCountRedux(state, action: PayloadAction<any>) {
      state.involved_vehicle_count = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialUserState);
    });
  },
});

export const { setDataRedux , setStateRedux, setInvolvedVehicleCountRedux} =
  mv104Slice.actions;

export default mv104Slice.reducer;
