import { roles } from "features/theme/constant";
import { lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import defaultRole from "constant/config.role";
import FleetOperatorList from "../pages/fleetOperator/list/index";

const Loader = lazy(() => import("ui-component/Loader"));
const PageNotFound = lazy(() => import("../pages/pagenotfound"));
const Login = lazy(() => import("../pages/authentication/Login"));
const Wrapper = lazy(() => import("../pages/chat/Wrapper"));
const ForgotPassword = lazy(
  () => import("../pages/authentication/ForgotPassword")
);
// const ResetPassword = lazy(() => import("../pages/authentication/ResetPassword"));
const SetPassword = lazy(() => import("../pages/authentication/SetPassword"));
const VerifyEmail = lazy(() => import("../pages/authentication/VerifyEmail"));
const Dashboard = lazy(() => import("../pages/admin/dashboard"));
const ServiceAgent = lazy(() => import("../pages/agent/list/index"));
const Driver = lazy(() => import("../pages/driver/list/index"));
const ViewDriver = lazy(() => import("../pages/driver/view/index"));
const ViewServiceAgent = lazy(() => import("../pages/agent/view/index"));
const EditServiceAgent = lazy(() => import("../pages/agent/mybusiness/index"));
const ViewMyBusinessServiceAgent = lazy(
  () => import("../pages/agent/mybusiness/index")
);
const ViewMyBusinessDriver = lazy(
  () => import("../pages/driver/mybusiness/index")
);
const FleetOperator = lazy(() => import("../pages/fleetOperator/list/index"));
const FleetOperatorAction = lazy(
  () => import("../pages/fleetOperator/view/index")
);
const ViewMybusinessFleetOperator = lazy(
  () => import("../pages/fleetOperator/mybusiness/index")
);
const Admin = lazy(() => import("../pages/siteadminisitration/index"));
const ExportReports = lazy(
  () => import("../pages/export-reports/vehicle/index")
);
const VehicleOwner = lazy(() => import("../pages/vehicleowner/list/index"));
const VehicleOwnerActions = lazy(
  () => import("../pages/vehicleowner/view/index")
);
const ViewMyBusinessVehicleowners = lazy(
  () => import("../pages/vehicleowner/mybusiness/index")
);
const ServiceProvider = lazy(
  () => import("../pages/serviceproviders/list/index")
);
const ServiceProvideAction = lazy(
  () => import("../pages/serviceproviders/view/index")
);
const ViewMyBusinessServiceprovider = lazy(
  () => import("../pages/serviceproviders/mybusiness/index")
);
const Vehicle = lazy(() => import("../pages/vehicle/list/index"));
const VehiclePermit = lazy(() => import("../pages/vehiclepermit/list/index"));
const Telematics = lazy(() => import("../pages/dongle/list"));
const TelematicsActions = lazy(() => import("../pages/dongle/view"));

const Layout = lazy(() => import("../layout/layout/MainLayout"));
const ComingSoon = lazy(() => import("../component/HOC/ComingSoon"));
const MailTextEditor = lazy(() => import("../pages/mail/MailEditor"));
// const ListDocument = lazy(() => import("../pages/document/ListDocument"));
const UploadDocument = lazy(() => import("../pages/document/UploadDocument"));
const ChangePassword = lazy(
  () => import("../pages/authentication/ChangePassword")
);
const Profile = lazy(() => import("layout/pages/profile-details/profile-basic-details"));
const ProfileEdit = lazy(() => import("layout/pages/profile-details/profile-basic-edit"));

const VehicleCreate = lazy(() => import("pages/vehicle/view"));
const VehiclePermitCreate = lazy(() => import("pages/vehiclepermit/view"));

const AdminListDocument = lazy(
  () => import("../pages/admin/documents/ListDocument")
);
const DriverAgentDocumentList = lazy(
  () => import("../pages/agent/documents/ListDocument")
);
const AccidentReport = lazy(() => import("../pages/accidentreport"));

const DocuSign = lazy(() => import("../pages/docusign"));

const DocuSignVerification = lazy(
  () => import("../pages/docusign/verification")
);
const Mv104Form = lazy(() => import("../pages/mv104form"));

/* export report route  */
const Support = lazy(() => import("../pages/support/support"));
const TicketExportReports = lazy(
  () => import("../pages/export-reports/ticketing")
);

const VehicleExportReports = lazy(
  () => import("../pages/export-reports/vehicle/index")
);
const MemberExportReports = lazy(
  () => import("../pages/export-reports/member/index")
);
const { admin, agent, driver } = roles;

const GuestRoute = (props: any) => {
  // const { auth } = useAuth()
  const auth = !!localStorage.getItem("at-dl-user")
    ? JSON.parse(localStorage.getItem("at-dl-user") || "")
    : null;
  let location = useLocation();
  return props?.roles.some((r: any) => r.includes(auth?.role)) ? (
    <Navigate
      to={`/${defaultRole.profile}/dashboard`}
      state={{ from: location }}
      replace
    />
  ) : (
    <Outlet />
  );
};

const ProtectedUserRoute = (props: any) => {
  // const { auth }= useAuth();
  const auth = !!localStorage.getItem("at-dl-user")
    ? JSON.parse(localStorage.getItem("at-dl-user") || "")
    : null;
  console.log("auth", auth);
  console.log("props?.roles", props?.roles);
  console.log(
    "props?.roles.some((r: any) => r.includes(auth?.role))",
    props?.roles.some((r: any) => auth?.role.includes(r))
  );
  let location = useLocation();
  return props?.roles.some((r: any) => auth?.role.includes(r)) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate
      to={`/${defaultRole.profile}/dashboard`}
      state={{ from: location }}
      replace
    />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          <Route path="/verify_email" element={<VerifyEmail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/auth_verification_token" element={<Login />} />
          <Route element={<GuestRoute roles={[admin, agent, driver]} />}>
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/setpassword/:verifyToken" element={<SetPassword />} />
            {/* <Route path="/resetpassword/:verifyToken" element={<ResetPassword />} /> */}
          </Route>
          <Route element={<Layout />}>
            <Route
              element={
                <ProtectedUserRoute
                  roles={[defaultRole.admin, defaultRole.adminuser]}
                />
              }
            >
              <Route
                path={`/${defaultRole.profile}/reports`}
                element={<VehicleExportReports />}
              />
              <Route
                path={`profile`}
                element={<Profile />}
              />
              <Route
                path={`/profile_edit`}
                element={<ProfileEdit />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/dashboard`}
                element={<Dashboard />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/serviceagents`}
                element={<ServiceAgent />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/serviceagent/view`}
                element={<ViewServiceAgent />}
              />

              <Route
                path={`/${defaultRole.admin}/portal/drivers`}
                element={<Driver />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/driver/view`}
                element={<ViewDriver />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/driver/edit`}
                element={<ViewDriver />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/fleetoperators`}
                element={<FleetOperator />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/fleetoperator/view`}
                element={<FleetOperatorAction />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/fleetoperator/edit`}
                element={<FleetOperatorAction />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/vehicleowners`}
                element={<VehicleOwner />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/vehicleowner/view`}
                element={<VehicleOwnerActions />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/vehicleowner/edit`}
                element={<VehicleOwnerActions />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/serviceproviders`}
                element={<ServiceProvider />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/serviceprovider/view`}
                element={<ServiceProvideAction />}
              />
              <Route
                path={`/${defaultRole.admin}/portal/serviceprovider/edit`}
                element={<ServiceProvideAction />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/vehiclelist`}
                element={<Vehicle />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/vehicle/view`}
                element={<VehicleCreate />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/vehicle/edit`}
                element={<VehicleCreate />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/vehiclepermits`}
                element={<VehiclePermit />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/vehiclepermit/view`}
                element={<VehiclePermitCreate />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/vehiclepermit/edit`}
                element={<VehiclePermitCreate />}
              />

              <Route
                path={`/${defaultRole.profile}/mybusiness/telematics`}
                element={<Telematics />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/telematics/view`}
                element={<TelematicsActions />}
              />
              <Route
                path={`/${defaultRole.admin}/mybusiness/telematics/edit`}
                element={<TelematicsActions />}
              />
              <Route
                path={`/${defaultRole.admin}/siteadministration/adminusers`}
                element={<Admin />}
              />
              <Route
                path={`/${defaultRole.admin}/reports/vehicle`}
                element={<VehicleExportReports />}
              />
              <Route
                path={`/${defaultRole.admin}/reports/member`}
                element={<MemberExportReports />}
              />
              <Route
                path={`/${defaultRole.admin}/reports/legaltickets`}
                element={<TicketExportReports />}
              />
              <Route
                path={`/${defaultRole.admin}/helpsupport`}
                element={<VehicleExportReports />}
              />
              <Route
                path={`/${defaultRole.admin}/siteadministration/documenttemplate/edit/:id`}
                element={<UploadDocument />}
              />
              <Route
                path={`/${defaultRole.admin}/siteadministration/documenttemplate/create`}
                element={<UploadDocument />}
              />
              <Route
                path={`/${defaultRole.admin}/siteadministration/documenttemplatelist`}
                element={<AdminListDocument />}
              />

              <Route
                path={`/${defaultRole.admin}/help/mail`}
                element={<MailTextEditor />}
              />
              <Route
                path={`/${defaultRole.admin}/help/chat`}
                element={<Wrapper />}
              />
              <Route
                path={`/${defaultRole.admin}/help/resetpassword`}
                element={<ChangePassword />}
              />
            </Route>
            <Route
              element={
                <ProtectedUserRoute
                  roles={[
                    defaultRole.agent,
                    defaultRole.driver,
                    defaultRole.fleet_operator,
                    defaultRole.service_provider,
                    defaultRole.vehicleowner,
                    defaultRole.adminuser,
                  ]}
                />
              }
            >
                            <Route
                path={`/profile`}
                element={<Profile />}
              />
                              <Route
                path={`/profile_edit`}
                element={<ProfileEdit />}
              />
              <Route
                path={`/${defaultRole.profile}/portal/dashboard`}
                element={<Dashboard />}
              />
              {/* <Route
                path={`/${defaultRole.profile}/mybusiness/serviceagents`}
                element={<ServiceAgent />}
              /> */}
              <Route
                path={`/${defaultRole.profile}/mybusiness/serviceagent/edit`}
                element={<EditServiceAgent />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/serviceagent/view`}
                element={<ViewMyBusinessServiceAgent />}
              />
              {/* <Route
                path={`/${defaultRole.profile}/mybusiness/driverlist`}
                element={<Driver />}
              /> */}
              <Route
                path={`/${defaultRole.profile}/mybusiness/driver/view`}
                element={<ViewMyBusinessDriver />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/driver/edit`}
                element={<ViewMyBusinessDriver />}
              />
              {/* <Route
                path={`/${defaultRole.profile}/mybusiness/fleetoperators`}
                element={<FleetOperator />}
              /> */}
              <Route
                path={`/${defaultRole.profile}/mybusiness/fleetoperator/view`}
                element={<ViewMybusinessFleetOperator />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/fleetoperator/edit`}
                element={<ViewMybusinessFleetOperator />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/serviceprovider/view`}
                element={<ViewMyBusinessServiceprovider />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/serviceprovider/edit`}
                element={<ViewMyBusinessServiceprovider />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/vehicleowner/view`}
                element={<ViewMyBusinessVehicleowners />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/vehicleowner/edit`}
                element={<ViewMyBusinessVehicleowners />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/vehicles`}
                element={<Vehicle />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/vehiclepermits`}
                element={<VehiclePermit />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/telematics`}
                element={<Telematics />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/telematics/view`}
                element={<TelematicsActions />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/telematics/edit`}
                element={<TelematicsActions />}
              />
              <Route
                path={`/${defaultRole.profile}/accidentreport`}
                element={<AccidentReport />}
              />
              <Route
                path={`/${defaultRole.profile}/accidentform`}
                element={<Mv104Form />}
              />
              <Route
                path={`/${defaultRole.profile}/accidentformview/:id`}
                element={<Mv104Form />}
              />
              <Route
                path={`/${defaultRole.profile}/accidentformedit/:id`}
                element={<Mv104Form />}
              />

              <Route
                path={`/${defaultRole.profile}/resetpassword`}
                element={<ChangePassword />}
              />

              {/* Documents Routes */}
              <Route
                path={`/${defaultRole.profile}/mybusiness/documents`}
                element={<DriverAgentDocumentList />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/accidentreport`}
                element={<AccidentReport />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/accidentform`}
                element={<Mv104Form />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/accidentform/view/:id`}
                element={<Mv104Form />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/accidentform/edit/:id`}
                element={<Mv104Form />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/document-sign`}
                element={<DocuSign />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/report-document-sign`}
                element={<DocuSign />}
              />
              <Route
                path={`/${defaultRole.profile}/mybusiness/docusign-verification`}
                element={<DocuSignVerification />}
              />

              <Route
                path={`/${defaultRole.profile}/help/mail`}
                element={<MailTextEditor />}
              />
              <Route
                path={`/${defaultRole.profile}/help/chat`}
                element={<Wrapper />}
              />
              <Route
                path={`${defaultRole.profile}/help/support`}
                element={<Support />}
              />
              {/* Documents Routes */}
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutePath;
