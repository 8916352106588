import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IMenuItems,
  IPopupItems,
} from "forms/helpers/interface.helper";
import { IUserSlice } from "interfaces/redux.interface";
import { IUser } from "interfaces/common.interface";
import { IVehicleDetails } from "interfaces/api.interface";

const initialUserState: IUserSlice = {
  token: null,
  user: {} as IUser,
  menuItems: [] as IMenuItems[],
  popupItems: [] as IPopupItems[],
  previousRoute: [],
  UserPermission: [],
  vehicleDetails: {} as IVehicleDetails,
  vehicleFormDataDetails: null,
  subMenuItems: [] as IMenuItems[],
  notiCount: 0
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setTokenRedux(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setUserRedux(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
    setMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.menuItems = action.payload;
    },
    setPopupItemsRedux(state, action: PayloadAction<IPopupItems[]>) {
      state.popupItems = action.payload;
    },
    setPreviousRouteRedux(state, action: PayloadAction<string>) {
      state.previousRoute.push(action.payload);
    },
    setVehicleDetails(state, action: PayloadAction<IVehicleDetails | any>) {
      state.vehicleDetails = action.payload;
    },
    setVehicleFormDataDetails(state, action: PayloadAction<IVehicleDetails | any>) {
      state.vehicleFormDataDetails = action.payload;
    },
    setSubMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.subMenuItems = action.payload;
    },
    setNotiCount(state, action: PayloadAction<number>) {
      state.notiCount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialUserState);
    });
  },
});

export const { setTokenRedux, setUserRedux, setMenuItemsRedux, setPopupItemsRedux,setPreviousRouteRedux,
  setVehicleDetails, setVehicleFormDataDetails, setSubMenuItemsRedux, setNotiCount } =
  userSlice.actions;

export default userSlice.reducer;
