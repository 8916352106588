import { useState } from "react";

import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import dayjs from "dayjs";

export const useSetState = (initialState: any) => {
  const [state, setData] = useState(initialState);

  const setState = (newState: any) => {
    setData((prevState: any) => ({ ...prevState, ...newState }));
  };

  return [state, setState];
};

export function parsePhoneNumber(phoneNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const countryCode = parsedNumber.getCountryCode();
    const nationalNumber: any = parsedNumber.getNationalNumber();

    return {
      countryCode: `+${countryCode}`,
      mobileNumber: nationalNumber.toString(),
    };
  } catch (err) {
    return {
      countryCode: null,
      mobileNumber: null,
    };
  }
}

export function parsePhoneNumberformat(countryCode: any, mobileNumber: any) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = `+${countryCode}${mobileNumber}`;
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    return formattedNumber;
  } catch (error) {
    return null; // Invalid phone number
  }
}

export function parsePhoneFormatNumber(phoneNumber: string) {
  try {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, 'ZZ');

    if (!phoneNumberUtil.isValidNumber(parsedNumber)) {
      return null; // Invalid phone number
    }

    const formattedNumber = phoneNumberUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

    return formattedNumber;
  } catch (error) {
    return phoneNumber
  }
}

export const formatSsn = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";
    if (val.length > 4) {
      finalValue = val;
    }
    if (val.length > 3 && val.length < 6) {
      newVal += val.substr(0, 3) + "-";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + "-";
      newVal += val.substr(3, 2) + "-";
      val = val.substr(5);
    }
    newVal += val;
    finalValue = newVal.substring(0, 11);

    return finalValue;
  }
  catch {
    return value
  }
};



export const formatZipcode = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";

    if (val.length > 5) {
      newVal += val.substr(0, 5) + "-";
      val = val.substr(5);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return value
  }
}

export const formatLicense = (value: any) => {
  try {
    let finalValue = "";

    var val = value.replace(/\D/g, "");
    var newVal = "";
    if (val.length > 4) {
      finalValue = val;
    }
    if (val.length > 3 && val.length < 7) {
      newVal += val.substr(0, 3) + " ";
      val = val.substr(3);
    }
    if (val.length > 5) {
      newVal += val.substr(0, 3) + " ";
      newVal += val.substr(3, 3) + " ";
      val = val.substr(6);
    }
    newVal += val;
    finalValue = newVal.substring(0, 11);

    return finalValue;
  } catch {
    return value
  }
};


export const handleKeyNumberPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  const enteredKey = event.key;
  if (!/^[0-9]$/.test(enteredKey)) {
    event.preventDefault();
  }
};

export const handleKeyDecimalPress = (event: any) => {
  const inputValue = event.key;
  // Allow digits, a decimal point (if not already present), and specific control keys
  const isValidKey =
    /^\d$/.test(inputValue) ||
    (inputValue === '.' && event.target.value.indexOf('.') === -1) ||
    event.key === 'Backspace' ||
    event.key === 'Delete' ||
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight' ||
    event.key === 'Home' ||
    event.key === 'End';

  if (!isValidKey) {
    event.preventDefault();
  }
}

export function dateFormat(dateString: any) {
  if(dateString?.length > 0){
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$1-$2-$3");
  return formattedDate;}else{
    return "-"
  }
}

export function dateTimeFormat(dateString: any) {
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, "$1-$2-$3 $4:$5:$6");
  return formattedDate;
}

export function dateTimeFormatWithoutSecond(dateString: any) {
  try {
    console.log('dateString', dateString);
    if(!dateString) return ""
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, "$1-$2-$3 $4:$5");
  return formattedDate;
  }  catch {
    return ""
  }
}

export const capitalizeFirstLetter = (string: string) => {
  if(!string) return '-'
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export function trimStringsInObject(obj: Record<string, any>): Record<string, any> {
  // Base case: if the input is not an object, return it as is
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  // Initialize a new object to store the trimmed values
  const trimmedObj: Record<string, any> = {};
  // Iterate through the keys of the input object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Check if the value is a string, and if so, trim it
      if (typeof value === 'string') {
        trimmedObj[key] = value.trim();
      } else {
        // If the value is not a string, leave it unchanged
        trimmedObj[key] = value;
      }
    }
  }
  return trimmedObj;
}

export function booleanToStringsInObject(obj: Record<string, any>): Record<string, any> {
  // Base case: if the input is not an object, return it as is
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  // Initialize a new object to store the trimmed values
  const modifiedObj: Record<string, any> = {};
  // Iterate through the keys of the input object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Check if the value is a string, and if so, trim it
      if (typeof value === 'string') {
        modifiedObj[key] = value.trim();
      } else if (typeof value === 'boolean') {
        // If the value is boolean, convert it to a string
        modifiedObj[key] = String(value);
      } else {
        // If the value is not a string or boolean, leave it unchanged
        modifiedObj[key] = value;
      }
    }
  }
  return modifiedObj;
}
export function dateFormatForm(dateString: any) {
  const date = new Date(dateString);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, (match, month, day, year) => {
      // Rearrange the date format to month day and year
      return `${month}/${day}/${year}`;
    });
  return formattedDate;
}
export const dateFnc = (data: any) => {

  const [day, month, year] = data.split('/');
  const newDateString = `${month}/${day}/${year}`;

  // Create a Date object from the new date string
  const dateObject = new Date(newDateString);
  const convertedDateString = dateFormatForm(dateObject);
  const formattedDate = dayjs(convertedDateString);
  if (formattedDate.isValid()) {
    console.log(formattedDate); // Output: 2026-03-13T00:00:00+00:00
    return formattedDate;
  } else {
    console.error("Invalid date format");
    return null;
  }
}


export function  getFormatedDisplayTime(time: any) {
  try {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(time));
  } catch (error) {
    return null;
  }
};

export const formatAllowOnlyNumericValues = (val: any, isCheckNumber = true) => {
  try {
    let finalValue = "";


    var newVal = "";

    if (val.length > 2) {
      newVal += val.substr(0, 2) + "-";
      val = val.substr(2);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return val
  }

};

export function dmvLicenseNumberMask(dmv_license_number: any){
  try {
    const maskedDigits = 'X'.repeat(dmv_license_number.length - 3);
    const lastThreeDigits = dmv_license_number.slice(-3);
    return maskedDigits + lastThreeDigits;
  } catch(error) {
   return dmv_license_number;
  }
}

/* Employer ID Number(EIN)/Tax ID formate for view */
export const EmployerIDNumberFormatForView = (value: any, isCheckNumber = true) => {
  try {
    let finalValue = "";

    var val = isCheckNumber ? value.replace(/\D/g, "") : value;
    var newVal = "";

    if (val.length > 2) {
      newVal += val.substr(0, 2) + "-";
      val = val.substr(2);
    }
    newVal += val;
    finalValue = newVal.substring(0, 10);

    return finalValue;
  }
  catch {
    return value
  }

};

export const  formatDate = (dateObj: any) => {
  const formattedDate = new Date(
    dateObj.$y,
    dateObj.$M,
    dateObj.$D
  ).toLocaleDateString("en", {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
  });
  return formattedDate;
}