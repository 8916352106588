import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from "./store/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./constant/config";
// style + assets
import "./index.scss";
import "./asset/scss/style.scss";
// import "./asset/fonts/Epilogue/Epilogue-VariableFont_wght.ttf"
// import "./asset/fonts/Poppins/Poppins-Regular.ttf";
import "./asset/fonts/Inter/Inter-VariableFont_slnt,wght.ttf";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeycloackContextProvider } from "pages/authentication/keycloak/useKeyCloak";
// import { ChatProvider } from "hooks/ChatProvider";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <ToastContainer />
      <KeycloackContextProvider >
      {/* <ChatProvider> */}
      <App />
      {/* </ChatProvider> */}
      </KeycloackContextProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
