import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import defaultRole from "constant/config.role";
// Define the DefaultEventsMap type as an alias for any
interface UserState {
  socket: any;
  userName: string;
  setUserName: string; // This should probably be a function, not a string. Please update it accordingly.
  currentRoom: any; // Assuming currentRoom is a string, if it's not, change the type accordingly
  receiverDetails: any;
  messages: string[]; // Assuming messages is an array of strings, if not, change the type accordingly
  user: any[]; // Replace 'any' with the type of 'user' if you have one
  isNavopen: boolean;
  isNewUser: boolean;
  addedMessage: boolean;
  isChatLoading: boolean;
  chatNotificationCount: number;
}
const initialUserState: UserState = {
  socket: null,
  userName: "",
  setUserName: "",
  currentRoom: null,
  receiverDetails: null,
  messages: [],
  user: [],
  isNavopen: false,
  isNewUser: true,
  addedMessage: false,
  isChatLoading: false,
  chatNotificationCount: 0,
};

const chatSlice = createSlice({
  name: "chat",
  initialState: initialUserState,
  reducers: {
    connectSocket(state, action: PayloadAction<any>) {
      try {
        state.socket = action.payload;
      } catch (err) {
        Failed(errorMessage(err, "Oops! Something went wrong!"));
      }
    },
    setCurrentRoom: (state, action): any => {
      state.currentRoom = action.payload;
    },
    setReceiverDetails: (state, action): any => {
      state.receiverDetails = action.payload;
    },
    setMessage: (state: any, action: PayloadAction<any>) => {
      const { message, userdetails } = action.payload;
      if (state.user.some((us: any) => us?.roomID === message.conversation)) {
        if (state?.currentRoom?.id !== message?.conversation) {
          if (state?.user.length <= 1) {
            let index = state.user.findIndex(
              (us: any) => us.roomID === message.conversation
            );
            if (index === -1) return;
            if (state.user?.length === 1) state.user[0].isNewMessage += 1;
            return; // Invalid index
          }

          let index = state.user.findIndex(
            (us: any) => us.roomID === message.conversation
          );
          if (index === -1) return;
          const element = state?.user.splice(index, 1)[0]; // Remove element at index
          state?.user.unshift({
            ...element,
            isNewMessage: element.isNewMessage + 1,
          });
        } else {
          let index = state.user.findIndex(
            (us: any) => us.roomID === message.conversation
          );
          if (index === -1) return;
          const element = state?.user.splice(index, 1)[0]; // Remove element at index
          state?.user.unshift({ ...element });
          if (state?.currentRoom?.id === message?.conversation) {
            if (state.messages.some((msg: any) => msg.id === message?.id))
              return;
            state.messages.unshift(message);
            if (
              window.location?.pathname === `/${defaultRole.admin}/chat` ||
              window.location?.pathname === `/${defaultRole.agent}/chat` ||
              window.location?.pathname === `/${defaultRole.driver}/chat`
            )
              state.socket.emit("read-message", {
                conversation: { id: message.conversation },
                user: userdetails,
              });
          }
        }
      } else {
        const chatRooms = {
          from: userdetails,
          to: message?.created_by?.id,
        };
        state.socket.emit("join-chat", chatRooms);
      }
    },
    joinedChat: (state: any, action: PayloadAction<any>) => {
      const { joinedRoom, userdetails } = action.payload;
      console.log("joinedRoom======", joinedRoom);
      const receiverDetail = joinedRoom?.members?.find(
        (x: any) => x.id !== userdetails
      );
      const data = receiverDetail?.agent
        ? receiverDetail?.agent
        : receiverDetail?.driver
        ? receiverDetail?.driver
        : receiverDetail?.admin;
      const dd = {
        id: receiverDetail?.id,
        email: receiverDetail?.email,
        name:
          receiverDetail?.profile?.firstname +
          " " +
          receiverDetail?.profile?.firstname,
      };
      if (!state.isNewUser) {
        state.currentRoom = joinedRoom;
        state.receiverDetails = dd;
        state.isNavopen = false;
        state.isNewUser = true;
      }
      if (state?.user?.some((u: any) => u.id === receiverDetail?.id)) return;
      state.user.unshift({
        data: receiverDetail,
        id: receiverDetail?.id,
        role: receiverDetail?.agent
          ? defaultRole.agent
          : receiverDetail?.driver
          ? defaultRole.driver
          : defaultRole.admin,
        roomID: joinedRoom?.id,
      });
    },
    setMessageList: (state: any, action: PayloadAction<any>) => {
      state.messages = action.payload;
    },
    setNewUser: (state: any, action: PayloadAction<any>) => {
      state.isNewUser = action.payload;
    },
    setNewMessage: (state: any, action: PayloadAction<any>) => {
      state.user.map((us: any) => {
        if (us.roomID === action.payload) us.isNewMessage = 0;
        return us;
      });
    },
    setUser: (state: any, action: PayloadAction<any>) => {
      const uniqueArray = action.payload.filter(
        (obj: any, index: number, arr: any) => {
          return index === arr.findIndex((o: any) => o.id === obj.id);
        }
      );
      state.user = uniqueArray;
    },
    addUserToChat: (state: any, action: PayloadAction<any>) => {
      if (state?.user?.some((u: any) => u.id === action.payload.id)) return;
      state.user.unshift(action.payload);
      state.isNavopen = false;
    },

    setisNavopen: (state, action): any => {
      state.isNavopen = action.payload;
    },
    setChatNotificationCount: (state, action): any => {
      state.chatNotificationCount = action.payload;
    },
    setChatLoading: (state, action): any => {
      state.isChatLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialUserState);
    });
  },
});

export const {
  joinedChat,
  setCurrentRoom,
  setReceiverDetails,
  setMessage,
  addUserToChat,
  setisNavopen,
  connectSocket,
  setUser,
  setMessageList,
  setNewUser,
  setNewMessage,
  setChatNotificationCount,
  setChatLoading,
} = chatSlice.actions;

export default chatSlice.reducer;
