import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./user.slice"
import customizationReducer from "features/theme/customizationReducer";
import chatSlice from 'hooks/ChatSlice';
import mv104Slice from "./mv104.slice";
const rootReducer = combineReducers({
    user: userSlice,
    customization: customizationReducer,
    chat: chatSlice,
    mv104: mv104Slice
});

export default rootReducer;