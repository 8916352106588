import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./App.css";
import NavigationScroll from "./container/layout/NavigationScroll";
import RoutePath from "./routes";
import themes from "./asset/themes";
import { setAxiosDefauls } from "helpers/interceptor";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setPreviousRouteRedux } from "store/slice/user.slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { connectSocket, setChatNotificationCount } from "hooks/ChatSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import { io } from "socket.io-client";
import { KeycloackContext } from "pages/authentication/keycloak/useKeyCloak";
import { clearStore } from "utils/redux.utils";

function App() {
  const customization = useAppSelector((state: any) => state.customization);
  const { socket } = useAppSelector((state) => state.chat);
  const { user } = useAppSelector((state) => state.user);

  setAxiosDefauls();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { keycloak, authenticated, setKeyclock, keycloakValue, logout } =
    useContext(KeycloackContext);
  useEffect(() => {
    // const fileName = location.pathname.split("/");
    // dispatch(setPreviousRouteRedux(`/${fileName[1]}/${fileName[2]}`));
    // if(authenticated)
    // setKeyclock();
  }, [location]);

  // useEffect(() => {
  //   const handleTokenExpired = () => {
  //     // Perform additional logout actions on your domain
  //     logout();
  //     console.log('User has been logged out on this domain');
  //   };
  //   // Set up the event listener
  //   keycloakValue.onTokenExpired = handleTokenExpired;

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     keycloakValue.onTokenExpired = null;
  //   };
  // }, []);
  useEffect(() => {
    keycloakValue.onAuthLogout = () => {
      console.log("User logged out");
      // Perform additional actions if needed
      // logout();
      clearStore();
      localStorage.clear();
      sessionStorage.clear();
    };

    return () => {
      // Clean up event listeners on component unmount
      keycloakValue.onAuthLogout = null;
    };
  }, []);
  useEffect(() => {
    if (socket && socket?.connected) return;
    if (user) {
      const socketConnect = io(
        // process.env.REACT_APP_DC_END_URL ||
        `${process.env.REACT_APP_DC_END_URL}/chat/socket/events`,
        {
          path: "/api/chat/socket/events",
          auth: {
            authorization: `Bearer ${localStorage.getItem("at-dl-token")}`,
            // authorization:
            //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNmM2I4Zjk0LTUzYTUtNDRhOC04M2EwLTQ5YjZjNWMzNTkwOCIsImVtYWlsIjoiYnJva2VyQGFnaXJhdGVjaC5jb20iLCJyb2xlIjp7ImlkIjoiZDczOTIzYjAtY2EyYy00Y2FiLTg2MGYtNzdmYTI5YWE3MzA3IiwibmFtZSI6ImJyb2tlciIsImNyZWF0ZWRfYXQiOiIyMDIzLTA3LTMxVDEwOjE3OjU5LjUyNVoiLCJ1cGRhdGVkX2F0IjoiMjAyMy0wNy0zMVQxMDoxNzo1OS41MjVaIn0sInR5cGUiOjAsImlhdCI6MTY5MDgwNTYyOSwiZXhwIjoxNjkwODkyMDI5fQ.N1zWnarZBla__gHuLe8UpAizlA14RptfDqpLApzGEoQ",
          },
          transports: ["websocket", "polling"],
        }
      );
      dispatch(connectSocket(socketConnect));
      console.log(socket);
    }
    // return () => {
    //   socket.disconnect();
    // };
  }, [user]);
  useEffect(() => {
    function onConnect() {
      // setSocket(true);
    }

    function onDisconnect() {
      // setSocket(false);
    }

    function getNotificationCount(count: any) {
      dispatch(setChatNotificationCount(count?.count ? count.count : 0));
    }
    if (socket) {
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("notification-count", getNotificationCount);
    }
    // return () => {
    //   socket.socket.off("connect", onConnect);
    //   socket.socket.off("disconnect", onDisconnect);
    // };
  }, [socket]);

  useEffect(() => {
    const handleTokenExpired = () => {
      // Perform additional logout actions on your domain
      console.log("User has been logged out on this domain");
    };

    // Set up the event listener
    keycloakValue.onTokenExpired = handleTokenExpired;

    // Clean up the event listener when the component unmounts
    return () => {
      keycloakValue.onTokenExpired = null;
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <RoutePath />
          </NavigationScroll>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
